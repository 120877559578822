@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
* {
  font-family: "Montserrat";
}

.bg-yellow {
  background-color: #ffc20e;
}

.btn-yellow {
  background-color: #ffc20e;
}

.btn-yellow:hover {
  background-color: #daa300;
}

.grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: "alert alert alert alert" "header header header header" "logo title title title" "logo nav nav nav" "side main main main" "footer footer footer footer";
}

.grid .alert-custom {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: alert;
  background-color: #c53030;
  margin-bottom: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
}

.grid .alert-custom h6 {
  margin: 0;
  color: white;
  font-weight: 700;
  text-align: center;
}

.grid .header {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: header;
  background-color: #ffc20e;
  height: 300px;
  background-image: url("../images/banner.jpg");
  background-size: cover;
  background-position: center;
}

.grid .header img {
  width: 100%;
}

.grid .logo {
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: logo;
  background-color: #ffc20e;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.grid .title {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: title;
  background-color: #ffc20e;
  padding: 30px;
}

.grid .title h1 {
  font-weight: 700;
}

.grid .title h3 {
  margin-bottom: 0;
}

.grid .nav {
  -ms-grid-row: 4;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: nav;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #ffc20e;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.grid .nav a {
  text-decoration: none;
  color: #000;
  padding: 20px 15px;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.grid .nav a:hover {
  background-color: #daa300;
}

.grid .nav a.active {
  font-weight: 700;
}

.grid .side {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: side;
  background-color: #d3d3d3;
  padding: 30px;
}

.grid .main {
  -ms-grid-row: 5;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: main;
  background-color: #fff;
  padding: 40px;
}

.grid .main h3 {
  margin-bottom: 25px;
}

.grid .main p {
  text-align: justify;
}

.grid .footer {
  -ms-grid-row: 6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: footer;
  background-color: #ffc20e;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .grid {
        grid-template-areas: "alert alert alert alert" "header header header header" "logo title title title" "nav nav nav nav" "side main main main" "footer footer footer footer";
  }
  .header {
    height: 250px !important;
  }
}

@media (max-width: 767.98px) {
  .grid {
        grid-template-areas: "alert alert alert alert" "logo logo logo logo" "header header header header" "title title title title" "nav nav nav nav" "main main main main" "side side side side" "footer footer footer footer";
  }
  .header {
    height: 200px !important;
  }
  .title {
    text-align: center;
  }
  .nav {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .nav a {
    padding: 16px 8px !important;
  }
  .container {
    padding: 0;
  }
}

@media (max-width: 575.98px) {
  .header {
    height: 150px !important;
  }
  .nav {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .nav a {
    width: 100%;
  }
}

.calendar {
  border: 1px solid #bababa;
  text-align: center;
  margin: 10px 0 20px;
}

.calendar h6 {
  font-weight: 700;
  margin: 10px;
}

.calendar .month {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-top: 1px solid #bababa;
}

.calendar .day,
.calendar .day-label {
  padding: 4px;
}

.calendar .day-label {
  font-weight: 700;
}

.accordion-header {
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
}

.accordion-body {
  border-top: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
}

.accordion-body p {
  margin: 10px 0 10px;
  padding: 0;
}

.accordion-body p a {
  color: #000;
}

.rk {
  height: 35px;
  margin-right: 5px;
}

.creator {
  color: black;
}

.creator:hover {
  color: black;
  text-decoration: underline;
}
